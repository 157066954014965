/*-----------------------------------*\
  #style.css
\*-----------------------------------*/

/**
 * copyright 2022 codewithsadee
 */





/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
   * colors
   */

  --pale-purple-pantone: hsl(262, 63%, 92%);
  --medium-sea-green: hsl(152, 63%, 43%);
  --lavender-blush: hsl(336, 35%, 92%);
  --carolina-blue: hsl(204, 91%, 53%);
  --royal-blue-light: hsl(225, 68%, 53%);
  --columbia-blue: hsl(204, 92%, 90%);
  --alice-blue-1: black;
  --alice-blue-2: hsl(216, 75%, 97%);
  --alice-blue-3: hsl(216, 38%, 95%);
  --alice-blue-4: hsl(217, 33%, 92%);
  --independence: hsl(219, 21%, 39%);
  --deep-cerise: hsl(329, 63%, 52%);
  --eerie-black: white;
  --space-cadet: white;
  --blue-jeans: hsl(204, 80%, 63%);
  --slate-blue: hsl(262, 60%, 57%);
  --beau-blue: hsl(208, 86%, 92%);
  --honey-dew: hsl(152, 48%, 89%);
  --mimi-pink: hsl(329, 63%, 90%);
  --red-salsa: hsl(0, 79%, 63%);
  --sapphire: hsl(211, 100%, 35%);
  --manatee: hsl(219, 14%, 60%);
  --white: hsl(0, 0%, 100%);

  --gradient: linear-gradient(to top, var(--alice-blue-2), var(--alice-blue-3));

  /**
   * typography
   */

  --ff-nunito: 'Nunito', sans-serif;
  --ff-open-sans: 'Open Sans', sans-serif;

  --fs-1: 2.125rem;
  --fs-2: 1.875rem;
  --fs-3: 1.5rem;
  --fs-4: 1.375rem;
  --fs-5: 1.125rem;
  --fs-6: 0.875rem;
  --fs-7: 0.625rem;

  --fw-400: 400;
  --fw-600: 600;

  /**
   * transition
   */

  --transition: 0.5s ease;

  /**
   * spacing
   */

  --section-padding: 50px;

  /**
   * radius
   */
  /**
   * border radius
   */

   --radius-6: 6px;
   --radius-4: 4px;
  --radius-10: 10px;
  --radius-14: 14px;
  --radius-18: 18px;

  /**
   * shadow
   */

  --shadow-1: 3px 3px 9px hsla(240, 14%, 69%, 0.2);
  --shadow-2: 3px 3px 9px hsla(204, 92%, 59%, 0.3);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a { text-decoration: none; }

a,
img,
span,
label,
input,
button,
ion-icon { display: block; }

button,
input {
  background: none;
  border: none;
  font: inherit;
}

button { cursor: pointer; }

input { width: 100%; }

ion-icon { pointer-events: none; }

html {
  font-family: var(--ff-open-sans);
  color: var(--eerie-black);
  font-size: 1rem;
  line-height: 1.5;
  scroll-behavior: smooth;
}

body {
  background: var(--alice-blue-1);
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 15px;
  height: 10px;
}

::-webkit-scrollbar-track { background: var(--white); }

::-webkit-scrollbar-thumb {
  background: hsla(219, 14%, 60%, 0.3);
  border: 2px solid var(--white);
}

::-webkit-scrollbar-thumb:hover { background: hsla(219, 14%, 60%, 0.5); }





/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 15px; }

button, a { transition: var(--transition); }

.btn {
  position: relative;
  background: var(--background, var(--carolina-blue));
  color: var(--color, var(--white));
  min-width: var(--width, 40px);
  min-height: var(--height, 40px);
  padding: 5px;
  display: grid;
  place-items: center;
  border-radius: var(--radius-14);
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  font-weight: var(--fw-600);
  overflow: hidden;
}

.btn ion-icon {
  font-size: 22px;
  --ionicon-stroke-width: 40px;
}

.btn::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(to right, hsla(0, 0%, 100%, 0.4), transparent);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}

.btn:is(:hover, :focus) { box-shadow: var(--shadow-2); }

.btn:is(:hover, :focus)::before { opacity: 1; }

.section { padding-block: var(--section-padding); }

.h1,
.h2,
.h3 {
  color: var(--space-cadet);
  font-family: var(--ff-nunito);
  line-height: 1.4;
}

.h1 {
  font-size: var(--fs-1);
  text-transform: uppercase;
}

.h2,
.h3 { font-weight: var(--fw-400); }

.h2 { font-size: var(--fs-2); }

.h3 { font-size: var(--fs-4); }

.w-100 { width: 100%; }


.btns {
  background-image: linear-gradient( var(--deg, 90deg), var(--carolina-blue) 0%, var(--royal-blue-light) 100%);
  color: var(--white);
  font-size: var(--fs-7);
  text-transform: uppercase;
  padding: 20px 30px;
  text-align: center;
  border-radius: var(--radius-4);
  box-shadow: var(--shadow-4);
}


/*-----------------------------------*\
  #HEADER
\*-----------------------------------*/

.header-contact { display: none; }

.header {
  background: var(--alice-blue-1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: var(--transition);
  z-index: 4;
}

.header.active { box-shadow: var(--shadow-1); }

.header .container {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-actions .btn span { display: none; }

.user-btn {
  --color: var(--independence);
  --background: var(--white);
  --shadow-2: var(--shadow-1);
  box-shadow: var(--shadow-1);
  padding: 10px
}

.nav-toggle-btn span {
  background: var(--independence);
  width: 22px;
  height: 2px;
  border-radius: 2px;
  margin-block: 8px;
  transform-origin: right;
  transition: var(--transition);
}

.nav-toggle-btn span.two { transform: scaleX(0.7); }

.nav-toggle-btn span.three { transform: scaleX(0.4); }

.nav-toggle-btn:is(:hover, :focus) span { background: var(--carolina-blue); }

.nav-toggle-btn.active span {
  transform: scaleX(1);
  background: var(--carolina-blue);
}

.navbar {
  background: var(--alice-blue-1);
  position: fixed;
  top: 70px;
  bottom: 0;
  right: -260px;
  max-width: 260px;
  width: 100%;
  padding: 20px 25px;
  border: 1px solid var(--white);
  box-shadow: var(--shadow-1);
  visibility: hidden;
  transition: 0.25s cubic-bezier(0.51, 0.03, 0.64, 0.28);
}

.navbar.active {
  visibility: visible;
  transform: translateX(-100%);
  transition: 0.5s cubic-bezier(0.33, 0.85, 0.56, 1.02);
}

.navbar-link {
  color: var(--space-cadet);
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  padding-block: 5px;
  margin-bottom: 15px;
}

.navbar-link:is(:hover, :focus) { color: var(--carolina-blue); }

.overlay {
  position: fixed;
  top: 70px;
  background: hsl(216, 38%, 95%);
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}

.overlay.active {
  opacity: 0.7;
  pointer-events: all;
}





/*-----------------------------------*\
  #HERO
\*-----------------------------------*/

.hero { padding-top: 120px;
 }

.hero-title {
  max-width: 20ch;
  margin-bottom: 15px;
}

.hero-text {
  color: var(--independence);
  line-height: 1.8;
  max-width: 30ch;

}

/* .hero-banner { display: none; } */

.hero-form {
  background: var(--gradient);
  border: 1px solid var(--white);
  box-shadow: var(--shadow-1);
  border-radius: var(--radius-18);
  font-family: var(--ff-nunito);
}

.input-wrapper { padding: 15px 20px; }

.input-wrapper:not(:last-of-type) { border-bottom: 1px solid hsla(0, 0%, 0%, 0.08); }

.input-label {
  color: var(--manatee);
  font-size: var(--fs-6);
  margin-bottom: 10px;
}

.input-field {
  color: var(--space-cadet);
  outline: 2px solid transparent;
  outline-offset: 5px;
  border-radius: 4px;
  transition: var(--transition);
}

.input-field:focus { outline-color: var(--carolina-blue); }

.input-field::placeholder { color: var(--independence); }

.hero-form .btn {
  --width: calc(100% - 40px);
  --height: 50px;
  margin: 20px;
  text-transform: uppercase;
}





/*-----------------------------------*\
  #FEATURED CAR
\*-----------------------------------*/

.featured-car .title-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px 15px;
  margin-bottom: 30px;
}

.featured-car-link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--independence);
  font-size: var(--fs-6);
}

.featured-car-link span { transition: var(--transition); }

.featured-car-link:is(:hover, :focus) span { color: var(--space-cadet); }

.featured-car-link ion-icon {
  margin-top: 3px;
  transition: var(--transition);
}

.featured-car-link:is(:hover, :focus) ion-icon { color: var(--carolina-blue); }

.featured-car-list {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 30px;
}

.featured-car-card {
  background: var(--gradient);
  border: 1px solid var(--white);
  border-radius: var(--radius-18);
  padding: 10px;
  box-shadow: var(--shadow-1);
}

.featured-car-card .card-banner {
  background: hsla(0, 0%, 0%, 0.2);
  aspect-ratio: 3 / 2;
  border-radius: var(--radius-18);
  overflow: hidden;
}

.featured-car-card .card-banner > img {
  height: 100%;
  object-fit: cover;
}

.featured-car-card .card-content { padding: 20px 10px 10px; }

.featured-car-card .card-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}

.featured-car-card .card-title { width: calc(100% - 60px); }

.featured-car-card .card-title > a {
  color: var(--independence);
    width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.featured-car-card .card-title > a:is(:hover, :focus) { color: var(--carolina-blue); }

.featured-car-card .year {
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  font-weight: var(--fw-600);
  padding: 3px 12px;
  border: 2px dashed hsla(204, 91%, 53%, 0.4);
  border-radius: var(--radius-14);
  color: var(--independence);
}

.featured-car-card .card-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.1);
  margin-bottom: 15px;
}

.featured-car-card .card-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.featured-car-card .card-list-item ion-icon {
  font-size: 20px;
  color: var(--carolina-blue);
  --ionicon-stroke-width: 38px;
}

.featured-car-card .card-item-text {
  color: var(--independence);
  font-size: var(--fs-6);
}

.featured-car-card .card-price-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.featured-car-card .card-price {
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  color: var( --independence);
}

.featured-car-card .card-price strong {
  font-size: var(--fs-3);
  font-weight: var(--fw-400);
}

.featured-car-card .btn:last-child {
  --height: 36px;
  min-width: 100%;
}

.featured-car-card .fav-btn {
  --background: var(--beau-blue);
  --color: var(--blue-jeans);
  --height: 36px;
  --width: 36px;
  --shadow-2: none;
}

.featured-car-card .fav-btn ion-icon { font-size: 18px; }

.featured-car-card .fav-btn:is(:hover, :focus) {
  --background: var(--lavender-blush);
  --color: var(--red-salsa);
}





/*-----------------------------------*\
  #GET START
\*-----------------------------------*/

.get-start .section-title { margin-bottom: 25px; }

.get-start-list {
  display: grid;
  gap: 20px;
}

.get-start-card {
  background: var(--white);
  padding: 20px;
  border-radius: var(--radius-18);
  border: 1px solid var(--white);
  transition: var(--transition);
}

.get-start-card:hover {
  background: var(--alice-blue-2);
  box-shadow: var(--shadow-1);
}

.get-start-card .card-icon {
  background: var(--icon-card-bg, var(--columnia-blue));
  color: var(--icon-card-color, var(--carolina-blue));
  height: 50px;
  width: 50px;
  display: grid;
  place-items: center;
  border-radius: var(--radius-14);
  margin-bottom: 20px;
}

.get-start-card .card-icon ion-icon {
  font-size: 26px;
  --ionicon-stroke-width: 45px;
}

.get-start-card .icon-1 {
  --icon-card-bg: var(--mimi-pink);
  --icon-card-color: var(--deep-cerise);
}

.get-start-card .icon-2 {
  --icon-card-bg: var(--columbia-blue);
  --icon-card-color: var(--carolina-blue);
}

.get-start-card .icon-3 {
  --icon-card-bg: var(--honey-dew);
  --icon-card-color: var(--medium-sea-green);
}

.get-start-card .icon-4 {
  --icon-card-bg: var(--pale-purple-pantone);
  --icon-card-color: var(--slate-blue);
}

.get-start-card .card-title {
  color: var(--alice-blue-1);
  font-family: var(--ff-nunito);
  font-weight: var(--fw-400);
  margin-bottom: 15px;
}

.get-start-card .card-text { color: var(--independence); }

.get-start-card .card-link {
  position: relative;
  color: var(--carolina-blue);
  width: max-content;
}

.get-start-card .card-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--carolina-blue);
  transition: var(--transition);
}

.get-start-card .card-link:is(:hover, :focus)::before { width: 100%; }


/*-----------------------------------*\
  #FOOTER
\*-----------------------------------*/

.footer {
  background: var(--alice-blue-1);
  color: var(--independence);
}

.footer a { color: inherit; }

.footer-top {
  padding-block: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 50px;
}

.footer-top .logo { margin-bottom: 20px; }

.footer-text {
  font-size: var(--fs-6);
  line-height: 1.8;
}

.footer-list { font-family: var(--ff-nunito); }

.footer-list:not(:last-of-type) { width: 50%; }

.footer-list:last-of-type {
  width: 100%;
  column-count: 2;
}

.footer-list-title {
  color: var(--space-cadet);
  font-weight: var(--fw-600);
  margin-bottom: 8px;
}

.footer-link {
  font-size: var(--fs-6);
  padding-block: 6px;
}

.footer-link:is(:hover, :focus) { color: var(--carolina-blue); }

.footer-list:last-child > li:first-child { column-span: all; }

.footer-bottom {
  background: var(--gradient);
  border: 1px solid var(--white);
  /* border-radius: var(--radius-18) var(--radius-18) 0 0; */
  padding: 10px;
}

.social-list {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.social-link { font-size: 20px; }

.social-link ion-icon { --ionicon-stroke-width: 40px; }

.social-link:is(:hover, :focus) { color: var(--carolina-blue); }

.copyright { font-size: var(--fs-6); }

.copyright > a { display: inline-block; }

.copyright > a:is(:hover, :focus) { color: var(--carolina-blue); }

/*  */
.input-box {
 padding: 5px;
 margin-bottom:10px;
 color:white !important;
}
.input {
  border: 1px solid white;
  padding: 5px;
  margin-bottom:10px;
  width: 50%;
  color:white !important;
 }
.imgbox {
  display: flex;
  margin-top: 10px;
  
}
.img{
  width:20%;
  height:25%;
  margin-right: 10px;
}
.detailbox{
  border: 1px solid grey;
  padding: 15px;
}
.box-container{
  border-bottom: 1px solid white;
  padding-bottom:20px
}
.box{
  display: flex;
  flex-direction: row;
}
.car-overview-box{
  border: 1px solid grey;
  padding:10px;
  border-radius: 10px;
}
.sbtn{
  /* position: relative; */
  background: var(--background, var(--carolina-blue));
  color: var(--color, var(--white));
  padding: 5px;
  /* display: grid; */
  /* place-items: center; */
  border-radius: var(--radius-14);
  font-family: var(--ff-nunito);
  font-size: var(--fs-6);
  font-weight: var(--fw-600);
  overflow: hidden;
}
.box {
  background:red,

}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.loginBox{
  background-color:white;
  height:400px
}
.input {
  color:black;
  padding: 15px;
}
.input label {
  color:black

}
.input input {
  border: 1px solid black;
  width: 100%;
}
.box-container-form {
  display: flex;
  flex-direction: column;
  
}
.brline{
  
  display: flex;
  flex-direction: row;
}
.brline:before,
.brline:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid #D9D9D9;
    ;
    margin: auto;
}


  .about-banner { margin-block-end: 0; }
  /*-----------------------------------*\
  #ABOUT
\*-----------------------------------*/

.about { padding-block-end: 0; }

.about-banner { margin-block-end: 50px; }

.about .section-text-1 { margin-block: 25px 15px; }

.about .btn {
  font-size: unset;
  max-width: max-content;
  margin-block-start: 30px;
  padding-inline: 15px;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (change as needed) */
  height: 0;
  overflow: hidden;
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.logo {
width: 20px;
height: 10rem;
}
.logo .logoimg{
width: 100px;
margin-top: 50px;
}
/* style={{width:100, marginTop:50 }} */
.MapBox {
  display: flex;
  flex-direction: row;
}
.item {
  height: 200px;
  width:50%;
  background-color:'red';
  margin-bottom: 20px;
}
.boldtext{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.addbox{
text-align: center;
}
/* style={{ marginTop:'20px', textAlign:'center',}} */
.address{
  /* background-color:red; */
  text-align: center;
  width:50%;
}
.imgg {
  background-color:green !important;
  width: 100% !important;
  margin-top: 60px !important;

}
/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for large than 350px screen
 */


@media (min-width: 350px) {

  /**
   * FEATURED CAR
   */

  .featured-car-card .card-list { grid-template-columns: 1fr 1fr; }

  .featured-car-card .card-price { margin-right: auto; }

  .featured-car-card .btn:last-child {
    min-width: max-content;
    padding-inline: 15px;
  }

}






/**
 * responsive for large than 580px screen
 */

@media (min-width: 580px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 540px;
    margin-inline: auto;
  }



  /**
   * HEADER
   */

  .header-actions .btn span {
    display: block;
    font-weight: var(--fw-400);
    padding-inline: 15px;
  }

  .header-actions .btn:first-of-type ion-icon { display: none; }

}





/**
 * responsive for large than 768px screen
 */

@media (min-width: 768px) {
  .cta-content { padding-block: 60px; }
  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 2.625rem;

  }

  /**
   * REUSED STYLE
   */

  .container { max-width: 720px; }
  /**
   * CTA
   */

   .cta { padding-block: 0; }

   .cta .container {
     display: none;
     grid-template-columns: 1fr 1fr;
     align-items: flex-end;
     gap: 60px;
   }
 
   .cta-banner { margin-block-end: 0; }
 


  /**
   * HEADER
   */

  .header-actions { gap: 30px; }

  .header-contact {
    display: block;
    text-align: center;
  }

  .header-contact .contact-link {
    color: var(--space-cadet);
    font-family: var(--ff-nunito);
    font-size: var(--fs-5);
    line-height: 1.3;
  }

  .header-contact .contact-link:is(:hover, :focus) { color: var(--carolina-blue); }

  .header-contact .contact-time {
    color: var(--independence);
    font-size: var(--fs-7);
  }



  /**
   * HERO
   */

  .hero {
    position: relative;
    min-height: 65vh;
    display: flex;
    align-items: center;
    z-index: 1;
  }

  .hero-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.8fr;
  }

  .input-wrapper:not(:last-of-type) {
    border-bottom: none;
    border-right: 1px solid hsla(0, 0%, 0%, 0.08);
  }

  .input-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hero-banner {
    display: block;
    /* background: url("./assests//images/hero-banner.jpg") no-repeat; */
    background-size: cover;
    background-position: left;
    position: absolute;
    top: 100px;
    bottom: 50px;
    left: 65%;
    width: 500px;
    border-radius: 30px;
    z-index: -1;
    margin-bottom:20px;
  }
  .hero-banner  img {
    border-radius: 30px;
    width: 3rem;
    height: 45rem;
  }


  /**
   * FEATURED CAR
   */

  .featured-car-list { grid-template-columns: repeat(2, minmax(0, 1fr)); }



  /**
   * GET START
   */

  .get-start-list { grid-template-columns: 1fr 1fr; }



  /**
   * FOOTER
   */

  .footer-brand { width: 100%; }

  .footer-text { max-width: 400px; }

  .footer-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-block: 10px;
    box-shadow: var(--shadow-1);
  }

  .social-list { margin-bottom: 0; }

}

/* .slick-slider {
  margin:0 -15px;
} */
.CBox .slick-slide {
  padding:10px;
  text-align:center;
  margin-left:10px;
}



/**
 * responsive for large than 992px screen
 */

@media (min-width: 992px) {
  .cta-content { padding-block: 80px; }

  .about .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
  }
    /**
   * CTA
   */

  /**
   * REUSED STYLE
   */

  .container { max-width: 960px; }



  /**
   * HERO
   */

  .hero .container { width: 100%; }



  /**
   * BLOG
   */

  .blog .has-scrollbar { padding-bottom: 50px; }

  .blog .has-scrollbar > li { max-width: 450px; }



  /**
   * FOOTER
   */

  .footer-list:not(:last-of-type) { width: 25%; }

  .footer-list:last-of-type { width: 50%; }

}
@media (max-width : 768px) {
  .cta { background-color: hsl(218, 70%, 18%); }
  .MapBox {
    display: flex;
    flex-direction: column-reverse;
  }
   .item {
    height: 200px;
    width:100%;
    background-color:green;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 30px;
  }
  .about-content{
    display: none;
  }
  .hero .container { 
    width: 100%;
    padding-bottom: 1px !important;
    display: flex;
    flex-direction: column;
  }
  .boldtext{
   
    text-align: center;
  }
  .address{
width:100%
  }
  .address p{
   
    text-align: center;
  }
  .hero-banner {
height: 600;

  }
  .hero-banner Slider {
  height:300
}
.logo {
  width: 20px;
  height: 8rem;
  }
  .container Slider{
    height:'10px' !important;
    background-color:'red'
  }
  .logo .logoimg{
    width: 100px;
    margin-top: 35px;
    }
    .hero {
      width: 100%;
      display: flex;
      flex-direction: column; /* Stack them vertically on mobile */
      align-items: center;
    }
    
    .container1 {
      width: 100%;
      padding: 15px;
      box-sizing: border-box; /* Ensure padding doesn't add to width */
    }
    
    .hero-banner {
      flex: 1;
      box-sizing: border-box;
      position: relative;
    }
    .hero-content {
      flex: 1;
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      
    }
    
    .hero-text {
      font-size: 20px;
      margin-bottom: 20px;
      text-align: center; 
      font-weight: bold;
      color: #D9D9D9;
      
    }
    .container1 {
      display: flex;
      flex-direction: column;
    }
    
    .hero-content {
      order: 2;
    }
  
    .hero-banner {
      order: 1;
    }
    .hero-banner  img {
      border-radius: 30px;
      width: 3rem;
      height: 25rem;
    }
    .about-content{
order: 1;
    }
    .about-banner{
order: 2;
    }
}


@media (max-width: 1200px) {
.btn{
  display: none;
}
.hero-banner  img {
  border-radius: 30px;
  width: 3rem;
  height: 30rem;
}

  /**
   * CTA
   */

   .cta .container { align-items: center; }

   .cta-banner { margin-block-start: -120px; }
}
/**
 * responsive for large than 1200px screen
 */

@media (min-width: 1200px) {
  .sbtn{
    display: none;
    }
    .about .container {
      grid-template-columns: 0.85fr 1fr;
      gap: 100px;
    }
    .MapBox {
      margin-top: 80px;
    }
    /**

   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 2.875rem;
    --fs-2: 2rem;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 1170px; }




  /**
   * HEADER
   */

  .overlay,
  .nav-toggle-btn { display: none; }

  .navbar,
  .navbar.active { all: unset; }

  .navbar-link {
    margin-bottom: 0;
    font-weight: var(--fw-600);
  }

  .navbar-list {
    display: flex;
    gap: 50px;
  }



  /**
   * HERO
   */

  .hero { min-height: 100vh; }

  .hero-banner {
    left: auto;
    right: 50px;
    width: 630px;
  }

  .hero-form { max-width: 900px; }

  .hero-form .btn {
    --width: calc(100% - 40px);
    --height: 50px;
    margin: 20px;
    text-transform: uppercase;
  }



  /**
   * FEATURED CAR 
   */

  .featured-car-list { grid-template-columns: repeat(3, minmax(0, 1fr)); }



  /**
   * GET START
   */

  .get-start-list { grid-template-columns: repeat(4, 1fr); }



  /**
   * BLOG
   */

  .blog .has-scrollbar > li {
    max-width: 340px;
    scroll-snap-align: center;
  }



  /**
   * FOOTER
   */

  .footer-brand { width: 33.33%; }

  .footer-text { max-width: 35ch; }

  .footer-list:not(:last-of-type) { width: 16.66%; }

  .footer-list:last-of-type { width: 33.33%; }



}
/* Mobile-first styles */



/* Tablet */
@media (min-width: 768px) {
  .hero-text {
      font-size: 18px;
      text-align: center;
     
  }

  .container1 {
      display: flex; /* Lay out side by side on tablet and up */
      flex-direction: row;
  }

  .hero-content {
      flex: 1; /* Take up equal space */
      padding-right: 20px; /* Some spacing between text and slider */
    
  }

  .hero-banner {
      flex: 2; /* Give more space to slider */
      overflow: hidden;
  }
}

/* Desktop */
@media (min-width: 992px) {
  .hero-text {
      font-size: 20px;
  }

  .hero {
      max-width: 1140px;
      margin: 0 auto;
  }
}

  /**
   * CTA
   */

  

   .cta-banner { margin-block-end: 50px; }
   
   .cta .section-subtitle { text-transform: capitalize; }
   
   .cta .section-title {
     color: hsl(0, 0%, 100%);
     font-size: var(--fs-3);
     margin-block-end: 30px;
   }
   
/* Add this to your CSS */
.container-box {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.container-box > .MuiGrid-item {
  padding: 16px;
}

.container-box > .MuiGrid-item:first-child {
  /* display: flex;
  justify-content: center; */
}

.login-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  text-align: center;
}

.imgg {
  height: auto;
  max-height: 100%;
}

/* Media query for screens with max-width 900px */
@media (max-width: 900px) {
  .container-box {
    flex-direction: column;
  }

  .container-box > .MuiGrid-item {
    width: 100%;
  }

  .container-box > .MuiGrid-item:first-child {
    order: 2;
  }

  .container-box > .MuiGrid-item:last-child {
    order: 1;
  }

  .hero-banner {
    height: auto;
    /* max-height: 300px; */
  }

  .login-box {
    padding: 10px;
  }
}
.about-content{
  display: flex;
  justify-content: center;
  align-items: center;
}